<script>
import { Button, Tabs, Input, Modal, message, Form } from 'ant-design-vue'
const FormItem = Form.Item
import { mapActions } from 'vuex'
import FormView from '@/components/FormView'
import { getAction, putAction } from '@/command/netTool'
export default {
  data() {
    return {
      username: '',
      password: '',
      phoneNumber: '',
      getCodeMsg: '发送验证码',
      countdown: 60,
      code: '',
      showEye: true,
      formType: 'form',
      // 重置密码提醒
      dialogModal: false,
      // 重置密码弹窗
      resetModal: false,
      // 重置弹窗的表单
      resetModalForm: {},
      // 密码错误
      forgotPasswordText: '',
    }
  },
  watch: {
    password(newValue) {
      if (!/^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/.test(newValue)) {
        this.forgotPasswordText = '密码需要包含8到20位大小写字母+数字+特殊字符'
      } else {
        this.forgotPasswordText = ''
      }
    },
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'GetCode', 'LoginByCode', 'GetCodeFind', 'UpdatePwd']),
    renderHeader() {
      return (
        <div class="login-header">
          <img src={require('./asset/header.png')} />
        </div>
      )
    },
    renderImg() {
      return <div class="login-img" />
    },
    loginSuccess(userData) {
      this.dialogModal = false
      this.resetModal = false
      if (this.$route.query.redirect) {
        window.location.href = this.$route.query.redirect + '?token=' + localStorage.getItem('CX-Access-Token')
      } else {
        window.location.href = '/index'
      }
    },
    queryPwdGrade() {
      getAction('/api/admin/my/getDefaultQd').then((e) => {
        if (e == '0') {
          this.dialogModal = true
          this.resetModalForm = { phone: this.username }
        } else {
          this.loginSuccess()
        }
      })
    },
    onLogin() {
      if (this.phoneNumber === '' || this.password === '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.phoneNumber,
        password: this.password,
        code:this.code
      })
        .then((result) => {
          this.queryPwdGrade(result)
        })
        .catch((err) => this.$message.error(err?.msg || '账号或密码或验证码错误'))
        .finally(() => {
          // state.loginBtn = false
        })
    },
    // 重置
    resetValue() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
      this.timeOut = ''
      this.username = ''
      this.password = ''
      this.phoneNumber = ''
      this.code = ''
      this.formType = 'form'
    },
    // 找回手机确认
    onNext() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      if (this.forgotPasswordText) {
        this.$message.error('密码需要包含8到20位大小写字母+数字+特殊字符')
        return
      }
      this.UpdatePwd({
        code: this.code,
        password: this.password,
        phone: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.resetValue()
          this.isShow = 'login-form-item'
          this.$message.success('修改密码成功')
          this.loginWayFindPass = !this.loginWayFindPass
          this.loginWay = !this.loginWay
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    // 验证码登录按钮
    onLoginByCode() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.LoginByCode({
        phoneNumber: this.phoneNumber,
        code: this.code,
      }).then((e) => {
        if (e.access_token) {
          this.loginSuccess(e)
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderLoginUserName() {
      return (
        <div class="login-username">
          <div>
            <Input placeholder={'手机号码'} v-model={this.phoneNumber} />
          </div>
          <div>
            <Input
              placeholder={'密码'}
              type={this.showEye ? 'password' : 'text'}
              v-model={this.password}
              onPressEnter={this.onLogin}
            />
            <img src={require('./asset/eye.png')} onClick={() => (this.showEye = !this.showEye)} />
          </div>
          {/* <div>
            <Input placeholder={'验证码'}  v-model={this.code} />
            <a onClick={this.getCode}>{this.getCodeMsg}</a>
          </div> */}
          <div onClick={this.changeFormType}>忘记密码</div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onLogin}>
            登录
          </Button>
        </div>
      )
    },
    getCodeFind() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCodeFind({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getCode() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCode({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown() {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + 's'
        this.getDisabled = true
        this.countdown--
      }
    },
    getCodeNow() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    changeFormType() {
      this.formType = 'forgot'
      clearInterval(this.timeOut)
      this.countdown = 60
      this.getCodeMsg = '发送验证码'
    },
    renderLoginPhone() {
      return (
        <div class="login-username">
          <div>
            <Input placeholder={'手机号码'} v-model={this.phoneNumber} />
          </div>
          <div>
            <Input placeholder={'验证码'} type={this.showEye ? 'password' : 'text'} v-model={this.code} />
            <a onClick={this.getCode}>{this.getCodeMsg}</a>
          </div>
          <div onClick={this.changeFormType}>忘记密码</div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onLoginByCode}>
            登录
          </Button>
        </div>
      )
    },
    renderForm() {
      const data = [
        {
          name: '登录',
          view: this.renderLoginUserName,
        },
        // {
        //   name: '手机登录',
        //   view: this.renderLoginPhone,
        // },
      ]
      return (
        <div class="login-form">
          <Tabs>
            {data.map((item) => {
              return (
                <Tabs.TabPane tab={item.name} key={item.name}>
                  {item.view()}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
      )
    },
    renderForgotPassword() {
      return (
        <div class="login-username forgot-password login-form">
          <span>设置新密码</span>
          <div>
            <Input placeholder={'手机号码'} v-model={this.phoneNumber} />
          </div>
          <div>
            <Input placeholder={'验证码'} type={this.showEye ? 'password' : 'text'} v-model={this.code} />
            <a onClick={this.getCodeFind}>{this.getCodeMsg}</a>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Input placeholder={'密码'} type={this.showEye ? 'password' : 'text'} v-model={this.password} />
              <img src={require('./asset/eye.png')} onClick={() => (this.showEye = !this.showEye)} />
            </div>
            {this.forgotPasswordText && <div style={{ fontSize: '14px', color: 'red' }}>{this.forgotPasswordText}</div>}
          </div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onNext}>
            修改密码
          </Button>
        </div>
      )
    },
    renderBody() {
      return (
        <div class="login-body">
          {this.renderImg()}
          {this.formType !== 'forgot' ? this.renderForm() : this.renderForgotPassword()}
        </div>
      )
    },
    renderFoot() {
      return (
        <div class="login-foot">
          <span>技术支持：浙江中兴慧农信息科技有限公司</span>
          &nbsp;|&nbsp;
          <a href="http://beian.miit.gov.cn" target="_blank">
            浙ICP备18036304号-5
          </a>
        </div>
      )
    },
    renderWarn(){
      return (
        <div class="warn">
          <div class="warn-text">
            本平台为互联网非涉密平台，严禁处理、传输国家秘密

          </div>
        </div>
      )
    },  
    onDialogOk() {
      this.dialogModal = false
      this.resetModal = true
    },
    onDialogCancel() {
      this.loginSuccess()
    },
    renderDialogModal() {
      return (
        <Modal
          visible={this.dialogModal}
          title="密码强度提醒"
          onOk={this.onDialogOk}
          onCancel={() => this.$message.error('请点击修改密码')}
        >
          密码强度过低 是否修改密码?
        </Modal>
      )
    },
    onResetSubmit() {
      this.$refs.form.sumbit().then((form) => {
        if (form.password !== form.fp_confirmPassword) {
          message.error('两次密码不一致请重新输入')
          return
        }
        putAction('/api/admin/my/updatePwd', {
          confirmPassword: form.password,
          newPassword: form.password,
          oldPassword: this.password,
        }).then(() => {
          this.$message.success('请重新登录')
          this.username = ''
          this.password = ''
          this.resetModal = false
          this.dialogModal = false
        })
      })
    },
    onSend() {
      return getAction(`/api/admin/my/send?phone=${this.phoneNumber}`)
    },
    renderResetModal() {
      const data = [
        /*{
          name: '手机号',
          type: 'inputCode',
          cols: 24,
          key: 'phone',
          disabled: true,
          onClick: this.onSend,
          rules: [
            {
              ruleType: 'phone',
              message: '请输入正确的手机号',
            },
          ],
        },
        {
          name: '验证码',
          type: 'input',
          key: 'yzCode',
          cols: 24,
        },*/
        {
          name: '密码',
          type: 'input',
          cols: 24,
          key: 'fp_confirmPassword',
          props: {
            type: 'password',
          },
          rules: [
            {
              pattern: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
              message: '密码需要包含8到20位大小写字母+数字+特殊字符',
            },
          ],
        },
        {
          name: '确认密码',
          type: 'input',
          cols: 24,
          key: 'password',
          props: {
            type: 'password',
          },
          rules: [
            {
              pattern: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
              message: '密码需要包含8到20位大小写字母+数字+特殊字符',
            },
          ],
        },
      ]
      return (
        <Modal
          visible={this.resetModal}
          title="修改密码"
          onOk={this.onResetSubmit}
          onCancel={() => this.$message.error('请修改密码')}
        >
          <FormView data={data} form={this.resetModalForm} ref={'form'} />
        </Modal>
      )
    },
  },
  render() {
    return (
      <div class="login-main">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFoot()}
        {this.renderWarn()}
        {this.renderDialogModal()}
        {this.renderResetModal()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  // width: 100%;
  width: calc(100vw);
  height: 100%;
}
.login-header {
  height: 100px;
  display: flex;
  align-items: center;
  width: calc(100vw);
  background-color: white;
  justify-content: center;
  // padding-left: 361px;
  img {
    width: 561px;
    height: 62px;
  }
}

.warn {
  position: fixed;
  left: 0;
  bottom: 120px;
  z-index: 999;
  width: 100%;

  .warn-text {
    text-align: center;
    font-size: 24px;
    color:red;
  }
  
}


.login-body {
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(149, 216, 211);
}
.login-foot {
  height: 100px;
  width: 100%;
  background: #0d8496;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;

  span {
    color: inherit;
  }

  a {
    color: inherit;
  }
}
.login-img {
  width: 592px;
  height: 559px;
  background-image: url('./asset/body-img.png');
  background-size: 100% 100%;
  margin-top: 90px;
}
.login-form {
  width: 460px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 0px solid #ffffff;
  margin-left: 70px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  /deep/.ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  /deep/.ant-tabs-bar {
    border: none;
  }
}
.login-username {
  display: flex;
  flex-direction: column;
  div {
    img {
      width: 24px;
      height: 18px;
      cursor: pointer;
    }
    a {
      white-space: nowrap;
    }
  }
  & > div:nth-of-type(-n + 2) {
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }
  & > div:nth-of-type(3) {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #4d4d4d;
    cursor: pointer;
  }
  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #b3b3b3;
  }
  Input {
    border: none;
    outline: none;
    width: calc(100% - 20px);
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #999999;
    }
  }
  button {
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.forgot-password {
  span:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4d4d4d;
  }
  & > div:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }
}
/deep/.ant-tabs-tab {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #4d4d4d;
}
/deep/.ant-tabs-tab-active {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #1e92ff;
}
/deep/.ant-input:focus {
  box-shadow: none;
}
</style>
